<template>
  <div class="auth-wrapper auth-v2">
    <b-row class="auth-inner m-0">

      <!-- Brand logo-->
      <b-link class="brand-logo">
        <!-- <vuexy-logo /> -->
            <span class="brand-logo">
                <b-img
                  :src="appLogoImage"
                  width="180px"
                  alt="logo"
                />
            </span>
        <!-- <h2 class="brand-text text-primary ml-1">
          Secusy
        </h2> -->
      </b-link>
      <!-- /Brand logo-->

      <!-- Left Text-->
      <b-col
        lg="8"
        class="d-none d-lg-flex align-items-center p-5"
      >
        <div class="w-100 d-lg-flex align-items-center justify-content-center px-5">
          <b-img
            fluid
            :src="imgUrl"
            alt="Login V2"
          />
        </div>
      </b-col>
      <!-- /Left Text-->

      <!-- Login-->
      <b-col
        lg="4"
        class="d-flex align-items-center auth-bg px-2 p-lg-5"
      >
        <b-col
          sm="8"
          md="6"
          lg="12"
          class="px-xl-2 mx-auto"
        >
          <b-card-title
            title-tag="h2"
            class="font-weight-bold mb-1"
          >
            Welcome to Secusy!
          </b-card-title>
          <b-card-text class="mb-2">
            Please sign up to start!
          </b-card-text>

          <!-- form -->
          <validation-observer ref="loginValidation">
            <b-form
              ref="loginForm" class="auth-login-form mt-2"
              @submit.prevent
            >
              <!-- Organization Name -->
              <b-form-group>
                <div class="d-flex justify-content-between">
                  <label for="organization-name">Organization Name</label>
                  <!-- <b-link :to="{name:'auth-forgot-password-v2'}">
                    <small>Forgot Password?</small>
                  </b-link> -->
                </div>
                <validation-provider
                  #default="{ errors }"
                  name="Organization Name"
                  rules="required"
                >
                  <b-input-group
                    class="input-group-merge"
                    :class="errors.length > 0 ? 'is-invalid':null"
                  >
                    <b-form-input
                      id="organization_name"
                      v-model="organization_name"
                      :state="errors.length > 0 ? false:null"
                      class="form-control-merge"
                      name="organization_name"
                      placeholder="Your Organization Name"
                    />
                  </b-input-group>
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
              <!-- Full Name -->
              <b-form-group>
                <div class="d-flex justify-content-between">
                  <label for="organization-name">Full Name</label>
                  <!-- <b-link :to="{name:'auth-forgot-password-v2'}">
                    <small>Forgot Password?</small>
                  </b-link> -->
                </div>
                <validation-provider
                  #default="{ errors }"
                  name="Full Name"
                  rules="required"
                >
                  <b-input-group
                    class="input-group-merge"
                    :class="errors.length > 0 ? 'is-invalid':null"
                  >
                    <b-form-input
                      id="fullname"
                      v-model="fullname"
                      :state="errors.length > 0 ? false:null"
                      class="form-control-merge"
                      name="fullname"
                      placeholder="Your Full Name"
                    />
                  </b-input-group>
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
              <!-- email -->
              <b-form-group
                label="Email"
                label-for="login-email"
              >
                <validation-provider
                  #default="{ errors }"
                  name="Email"
                  rules="required|email"
                >
                  <b-form-input
                    id="signup-email"
                    v-model="email"
                    :state="errors.length > 0 ? false:null"
                    name="email"
                    placeholder="Enter your work email"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>

              <!-- forgot password -->
              <b-form-group>
                <div class="d-flex justify-content-between">
                  <label for="login-password">Password</label>
                </div>
                <validation-provider
                  #default="{ errors }"
                  name="Password"
                  rules="required"
                >
                  <b-input-group
                    class="input-group-merge"
                    :class="errors.length > 0 ? 'is-invalid':null"
                  >
                    <b-form-input
                      id="login-password"
                      v-model="password"
                      :state="errors.length > 0 ? false:null"
                      class="form-control-merge"
                      :type="passwordFieldType"
                      name="password"
                      placeholder="············"
                    />
                    <b-input-group-append is-text>
                      <feather-icon
                        class="cursor-pointer"
                        :icon="passwordToggleIcon"
                        @click="togglePasswordVisibility"
                      />
                    </b-input-group-append>
                  </b-input-group>
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>

              <!-- submit buttons -->
              <b-button
                type="submit"
                variant="primary"
                block
                @click="validationForm"
              >
                Sign Up
              </b-button>
            </b-form>
          </validation-observer>

        </b-col>
      </b-col>
    <!-- /Login-->
    </b-row>
  </div>
</template>

<script>
/* eslint-disable global-require */
/* eslint-disable */
import { ValidationProvider, ValidationObserver } from 'vee-validate'
// import VuexyLogo from '@core/layouts/components/Logo.vue'
import {
  BRow, BCol, BLink, BFormGroup, BFormInput, BInputGroupAppend, BInputGroup, BCardText, BCardTitle, BImg, BForm, BButton,
} from 'bootstrap-vue'
import { required, email } from '@validations'
import { togglePasswordVisibility } from '@core/mixins/ui/forms'
import store from '@/store/index'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import { $themeConfig } from '@themeConfig'

export default {
  components: {
    BRow,
    BCol,
    BLink,
    BFormGroup,
    BFormInput,
    BInputGroupAppend,
    BInputGroup,
    // BFormCheckbox,
    BCardText,
    BCardTitle,
    BImg,
    BForm,
    BButton,
    // VuexyLogo,
    ValidationProvider,
    ValidationObserver,
  },
  mixins: [togglePasswordVisibility],
  data() {
    return {
      status: '',
      password: '',
      userEmail: '',
      organization_name: '',
      fullname: '',
      sideImg: require('@/assets/images/pages/login-v2.svg'),
      // validation rulesimport store from '@/store/index'
      required,
      email,
      info: '',
    }
  },
  computed: {
    passwordToggleIcon() {
      return this.passwordFieldType === 'password' ? 'EyeIcon' : 'EyeOffIcon'
    },
    imgUrl() {
      if (store.state.appConfig.layout.skin === 'dark') {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.sideImg = require('@/assets/images/pages/login-v2-dark.svg')
        return this.sideImg
      }
      return this.sideImg
    },
  },
  methods: {
    validationForm() {
      this.$refs.loginValidation.validate().then(success => {
        if (success) {
        //   console.log(this.$refs.loginForm.organization_name.value)
          const options = {
            method: 'POST',
            headers: { 'content-type': 'application/json' },
            data: {
              organization_name: this.$refs.loginForm.organization_name.value,
              fullname: this.$refs.loginForm.fullname.value,
              email: this.$refs.loginForm.email.value,
              password: this.$refs.loginForm.password.value,
            },
            url: 'http://localhost:8001/organization/sign-up',
          }
          this.$http(options).then( ( res ) => {
            this.toastMessage(res)  // method call to method2 
            } )
          
        }
      })
    },
    toastMessage(response){
        // statements
        console.log(response.data) 
        var title = ''
        if (response.data.org_id) 
            title = 'Successfully registered your organization. Please check your email'
        else {
            for ( var attr in response.data) {
                if(attr == 'username')
                    title = title + response.data.username[0]+'\n'
            }
        //    title = response.data.username[0]
            // title = 'Error, please try again later'
        }

        this.$toast({
            component: ToastificationContent,
            props: {
                title: title,
                icon: 'EditIcon',
                variant: 'success',
            },
        })


    } 
  },
  setup() {
    const { appName, appLogoImage } = $themeConfig.app
    return {
    // App Name
      appName,
      appLogoImage,
    }
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/pages/page-auth.scss';
</style>
